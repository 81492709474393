export const cn = {
  connect: '会话已连接',
  willReconnect: '会话正在重连',
  disconnect: '会话已断开',
  disconnectDlg: {
    title: '当前账号已下线',
    content: '您的账号已在其他设备登录，如果这不是您的操作，请及时修改您的登录密码',
    okText: '我知道了',
  },
  error: '会话链接错误',
};
export const en = {
  connect: 'Conversation Connected',
  willReconnect: 'Conversation Reconnecting',
  disconnect: 'Conversation Disconnected',
  disconnectDlg: {
    title: '',
    content:
      'Your account is logged in on another device. If this is not you, please change your login password in time.',
    okText: 'OK',
  },
  error: 'Conversation Connection Error',
};
