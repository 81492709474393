import { isString } from 'lodash-es';

// eslint-disable-next-line import/prefer-default-export
export function isURL(target: any) {
  return isString(target) && /https?:\/\/.+/.test(target);
}

export const isInIframe = window.self !== window.top;

export const isIM1 = isInIframe;
