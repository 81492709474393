/**
 * 根据目标类型 `TTarget` 生成可提取的类型
 * @template TTarget 目标类型
 * @template TReturnType 返回类型
 */
export type Refinable<TTarget> = TTarget extends (...args: any[]) => infer TReturnType
  ? TReturnType | TTarget
  : TTarget;

/**
 * 根据目标类型 `TTarget` 生成 `Refinable` 的参数类型
 * @template TTarget 目标类型
 * @template TParameters 参数类型
 */
export type RefinableParameters<TTarget> = TTarget extends (...args: infer TParameters) => any
  ? TParameters
  : never;

/**
 * 根据目标类型 `TTarget` 生成 `Refinable` 的返回类型
 * @template TTarget 目标类型
 * @template TReturnType 返回类型
 */
export type RefinableReturnType<TTarget> = TTarget extends (...args: any[]) => infer TReturnType
  ? TReturnType
  : TTarget;

/**
 * 提取目标的值
 * @template TTarget 目标类型
 * @param target 目标
 * @param args 提取参数
 */
function refine<TTarget = any>(
  target: TTarget,
  ...args: RefinableParameters<TTarget>
): RefinableReturnType<TTarget> {
  return typeof target === 'function' ? target(...args) : target;
}

export default refine;
