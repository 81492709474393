export const cn = {
  'system.remind': '消息通知',
  'system.remind.notic': '弹窗通知',
  'system.remind.notic.will.off.tips': '关闭后，当买家有新消息进入，不再弹出该条消息。',
  'system.remind.notic.will.on.tips': '开启后，当买家有新消息进入，会弹出该条消息。',
  'system.remind.sound': '声音提醒',
  'system.remind.sound.will.off.tips': '关闭后，当买家有新消息进入，不再有声音提示。',
  'system.remind.sound.will.on.tips':
    '开启后，当买家有新消息进入，以声音的方式提示你有新消息提醒。',
  'system.language': '系统语言',
  'system.on.quit': '客户端关闭设置',
  'system.on.quit.hidden': '隐藏到系统任务栏，不退出程序',
  'system.on.quit.close': '关闭',
  'system.password.management': '密码管理',
  'system.password.change': '修改密码',
};
export const en = {
  'system.remind': 'Reminds',
  'system.remind.notic': 'Notifications',
  'system.remind.notic.will.off.tips': 'When it is closed, the latest message will not pop up.',
  'system.remind.notic.will.on.tips': 'When it is open, the latest message will pop up.',
  'system.remind.sound': 'Sounds',
  'system.remind.sound.will.off.tips':
    'When it is closed, the latest message sounds will be muted.',
  'system.remind.sound.will.on.tips': 'When it is open, the latest message will get a sound alert.',
  'system.language': 'Language',
  'system.on.quit': 'On quit',
  'system.on.quit.hidden': 'Continue run in Minium Tray, stop closing',
  'system.on.quit.close': 'Close',
  'system.password.management': 'Password',
  'system.password.change': 'Change Password',
};
