export function cnCommonApiGetErr(tip: string) {
  return `获取 ${tip} 失败，请稍后再试`;
}
export function enCommonApiGetErr(tip: string) {
  return `Get ${tip} fail, please try again later.`;
}
export function cnCommonApiPostErr(tip: string) {
  return `${tip} 操作失败，请稍后再试`;
}
export function enCommonApiPostErr(tip: string) {
  return `${tip} operation fail, please try again later.`;
}
