class Cache {
  _storage: Storage;

  _entry: string;

  constructor(storage: Storage, entry: string = '/') {
    this._storage = storage;
    this._entry = entry;
  }

  entry(entry: string) {
    return new Cache(this._storage, entry);
  }

  getItem<T>(key: string): T | null {
    const value = this._storage.getItem(key);

    if (value === null) {
      return null;
    }

    try {
      return JSON.parse(value);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('get item from "%s" parse value "%s" error: %s', key, value, err);

      return null;
    }
  }

  setItem<T = any>(key: string, value: T) {
    this._storage.setItem(key, JSON.stringify(value));

    return this;
  }

  removeItem(key: string) {
    this._storage.removeItem(key);

    return this;
  }

  clear() {
    this._storage.clear();

    return this;
  }

  get<T>() {
    return this.getItem<T>(this._entry);
  }

  set<T = any>(value: T) {
    return this.setItem(this._entry, value);
  }

  remove() {
    return this.removeItem(this._entry);
  }
}

export default Cache;
