import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouterConfig } from 'types';
import { isIM1 } from 'utils/is';

/**
 * 404页面
 */
const NotFound = lazy(() => import('views/NotFound'));
/**
 * 403页面
 */
const Forbidden = lazy(() => import('views/Forbidden'));
/**
 * 主页面
 */
const Main = lazy(() => import('views/Main'));

/**
 * 智能营销
 */
const Marketing = lazy(() => import(/* webpackPrefetch: true */ 'views/Marketing/pages/Marketing'));
const Region = lazy(() => import(/* webpackPrefetch: true */ 'views/Marketing/pages/Region'));
const Scenario = lazy(() => import(/* webpackPrefetch: true */ 'views/Marketing/pages/Scenario'));
const OrderTask = lazy(() => import(/* webpackPrefetch: true */ 'views/Marketing/pages/Task'));
const OrderPolicy = lazy(() => import(/* webpackPrefetch: true */ 'views/Marketing/pages/Policy'));

/**
 * 问答
 */
const Qa = lazy(() => import('views/QA/Qa'));

/**
 * 店铺配置组
 */
const StoreGroupList = lazy(() => import('views/QA/pages/StoreGroup/List'));
const StoreGroupConfig = lazy(() => import('views/QA/pages/StoreGroup/Config'));

/**
 * iframe 子应用 neuron
 */
const Neuron = lazy(() => import('views/Neuron'));

/**
 * 会话
 */
const Conversation = lazy(() => import('views/Conversation'));

/**
 * 设置
 */
const Setting = lazy(() => import('views/Setting'));

/**
 * 开店流程相关
 */
const Login = lazy(() => import('views/Login'));
// const ShopeeAuth = lazy(() => import('views/ShopeeAuthCallback'));

/**
 * 客服管理
 */
const Assistant = lazy(() => import('views/Assistant'));

/**
 * 路由配置
 */
const routerConfig: RouterConfig = {
  history: 'hash',
  routes: [
    {
      path: '/',
      redirect: true,
      helmet: {
        defaultTitle: '企微客服',
        titleTemplate: '%s - 企微客服',
      },
      children: [
        {
          path: '/login',
          element: <Login />,
          helmet: {
            title: '登录',
          },
        },
        {
          path: '/403',
          element: <Forbidden />,
          helmet: {
            title: '403',
          },
        },
      ],
    },
    {
      path: '/',
      redirect: true,
      element: <Main />,
      helmet: {
        defaultTitle: 'CHAT++',
        titleTemplate: '%s - CHAT++',
      },
      children: [
        {
          path: 'id/marketing', // 微上客项目中跳转营销的路径，为了不修改微上客，在此做兼容。
          element: <Navigate replace to="/marketing" />,
        },
        {
          path: 'marketing',
          element: <Marketing />,
          redirect: true,
          access: ({ MARKETING_CONFIG }) => (MARKETING_CONFIG || isIM1 ? 'write' : false),
          menu: {
            label: '智能营销',
          },
          i18nHelmetTitle: 'sider.nav.marketing',
          children: [
            {
              path: ':region',
              element: <Region />,
              redirect: 'order',
              children: [
                {
                  path: ':scenario',
                  redirect: true,
                  children: [
                    {
                      path: 'task',
                      element: <OrderTask />,
                    },
                    {
                      path: 'policy',
                      element: <OrderPolicy />,
                    },
                    {
                      path: ':tab',
                      redirect: 'config',
                      element: <Scenario />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: ':region/qa',
          redirect: true,
          element: <Qa />,
          access: ({ QA_CONFIG }) => (QA_CONFIG || isIM1 ? 'write' : false),
          menu: {
            label: '智能问答',
          },
          i18nHelmetTitle: 'sider.nav.qa',
          children: [
            {
              path: 'storeGroup',
              redirect: true,
              children: [
                {
                  path: 'list',
                  redirect: true,
                  element: <StoreGroupList />,
                },
                {
                  path: 'config',
                  redirect: true,
                  element: <StoreGroupConfig />,
                },
              ],
            },
          ],
        },
        {
          path: ':region/neuron',
          element: <Neuron />,
          access: ({ QA_CONFIG }) => (QA_CONFIG || isIM1 ? 'write' : false),
          i18nHelmetTitle: 'sider.nav.qa',
        },
        {
          path: 'conversation',
          element: <Conversation />,
          access: ({ ASSIGN, SUPERVISE }) => 'write',
          i18nHelmetTitle: 'sider.nav.conversation',
        },
        {
          path: 'setting/*',
          element: <Setting />,
          access: () => 'write',
          i18nHelmetTitle: 'sider.nav.setting',
        },
        {
          path: 'setting',
          element: <Setting />,
          access: () => 'write',
          i18nHelmetTitle: 'sider.nav.setting',
        },
        {
          path: 'assistant/*',
          element: <Assistant />,
          access: ({ ASSISTANT_MANAGER }) => (ASSISTANT_MANAGER ? 'write' : false),
          i18nHelmetTitle: 'sider.nav.assistant',
        },
        {
          path: 'assistant',
          element: <Assistant />,
          access: ({ ASSISTANT_MANAGER }) => (ASSISTANT_MANAGER ? 'write' : false),
          i18nHelmetTitle: 'sider.nav.assistant',
        },
        {
          path: '*',
          element: <NotFound />,
          helmet: {
            title: '404',
          },
        },
      ],
    },
  ],
};

export default routerConfig;
