// import { useAuthorization } from 'components/Authorization';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { tempPermissionSessionCache } from 'services/cache';

export type PermissionMap = Partial<Record<Assistant.PermissionName, boolean>>;

const getDefaultTempMap = () => {
  const map = tempPermissionSessionCache.get<PermissionMap>();
  return map || ({} as PermissionMap);
};
// 临时开放权限表
const tempPermissionMapState = atom<PermissionMap>({
  key: 'tempPermissionMap',
  default: getDefaultTempMap(),
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((next) => {
        tempPermissionSessionCache.set(next);
      });
    },
  ],
});
export const useTempPermissionUpdater = () => {
  return useRecoilCallback(
    ({ set }) =>
      /**
       * 描述
       * @date 2021-09-10
       * @param {any} name:Assistant.PermissionName
       * @param {any} permission:boolean|'remove', remove means remove from the temp map
       * @returns {any}
       */
      async (name: Assistant.PermissionName, permission: boolean | 'remove') => {
        set(tempPermissionMapState, (map) => {
          const { ...next } = map;
          if (permission === 'remove') {
            delete next[name];
          } else {
            next[name] = permission;
          }
          return next;
        });
      },
    [],
  );
};
function usePermission() {
  // const { payload } = useAuthorization<WechatWork.Token.Payload>();
  // const { roles = [] } = payload || {};
  const tempPermissionMap = useRecoilValue(tempPermissionMapState);
  // return useMemo(() => {
  //   const permissionMap = roles.reduce<PermissionMap>((res, role) => {
  //     const { permissions } = role;

  //     permissions.forEach((permission) => {
  //       const { name } = permission;

  //       res[name] = true;
  //     });

  //     return res;
  //   }, {});

  //   return { ...permissionMap, ...tempPermissionMap };
  // }, [roles, tempPermissionMap]);
  return tempPermissionMap;
}

export default usePermission;
