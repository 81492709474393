export const cn = {
  convList: {
    filter: {
      pin: '星标',
      unread: '未读',
      all: '会话状态',
      empty: '无店铺数据',
      store: '全部店铺',
      allCountry: '切换至全部店铺',
    },
    ops: {
      forward: '转发',
      unread: '标为未读',
      read: '标为已读',
      pin: '标为星标',
      unpin: '取消星标',
      del: '删除会话',
      delConfirmTitle: '你确定要删除该会话信息吗？',
    },
    notAssign: '未分配',
  },
  forward: {
    title: '转发会话',
    client: '客服',
    clientTip: '请选择客服',
    clientGroup: '客服组',
    clientGroupTip: '请选择客服组',
    clientSel: '选择客服',
    clientGroupSel: '选择客服组',
    note: '转发备注',
    online: '在线',
    offline: '离线',
    hangup: '挂起',
    onlineUsers: '在线人数',
    undisposedConversation: '会话未处理数',
    toClientTip: '是否确认转发本次会话给该客服？',
    toGroupTip: '是否确认转发本次会话给该客服组？',
    btnOk: '确 定',
    btnCancel: '取 消',
  },
};
export const en = {
  convList: {
    filter: {
      pin: 'Star',
      unread: 'Unread',
      all: 'View all',
      empty: 'No Stores added',
      store: 'All Stores',
      allCountry: 'Switch to all stores',
    },
    ops: {
      forward: 'Forward',
      unread: 'Mark as Read',
      read: 'Mark as Unread',
      pin: 'Star',
      unpin: 'Unstar',
      del: 'Delete this conversation',
      delConfirmTitle: 'Are you sure to delete this conversation?',
    },
    notAssign: 'Unassigned',
  },
  forward: {
    title: 'Forward conversation',
    client: 'Assistant',
    clientTip: 'Please choose an assistant',
    clientGroup: 'Assistant Group',
    clientGroupTip: 'Please choose an assistant group',
    clientSel: 'Assistant',
    clientGroupSel: 'Assistant group',
    note: 'Forward Notes',
    online: 'Online',
    offline: 'Offline',
    hangup: 'Hangup',
    onlineUsers: 'Online Users',
    undisposedConversation: 'Undisposed Conversation',
    toClientTip: 'Are you sure to forward this conversation to this assistant?',
    toGroupTip: 'Are you sure to forward this conversation to this assistant group?',
    btnOk: 'OK',
    btnCancel: 'Cancel',
  },
};
