import { message } from 'antd';
import { useCallback } from 'react';
import { atom, selector, useRecoilCallback, useRecoilValue } from 'recoil';
import i18n from 'i18next';
import { syncSysSettings } from 'services/chattt';

type MessageRemind = {
  soundRemind: boolean;
  popupRemind: boolean;
};

type CloseType = 'hidden' | 'close';

interface SystemSetting {
  messageRemind: MessageRemind;
  language: General.Language;
  closeType: CloseType;
}
const SYSTEM_SETTING_STORAGE_KEY = 'fett/systemSetting';
const getDefaultSystemSetting = async (): Promise<SystemSetting> => {
  const storage = localStorage.getItem(SYSTEM_SETTING_STORAGE_KEY);
  let language: General.Language = 'zh-CN';
  let cached: SystemSetting | undefined;
  if (storage) {
    cached = JSON.parse(storage) as SystemSetting;
    language = cached.language ?? language;
  }
  i18n.changeLanguage(language);
  if (cached) {
    return cached;
  }
  const permission = await window.Notification.requestPermission();

  return {
    messageRemind: {
      soundRemind: true,
      popupRemind: permission === 'granted',
    },
    language,
    closeType: 'hidden',
  };
};

const systemSettingState = atom({
  key: 'systemSettingState',
  default: getDefaultSystemSetting(),
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((state) => {
        localStorage.setItem(SYSTEM_SETTING_STORAGE_KEY, JSON.stringify(state));
        i18n.changeLanguage(state.language);
        syncSysSettings({
          language: state.language,
          quitMode: state.closeType,
        });
      });
    },
  ],
});
const systemLanguageState = selector({
  key: 'systemLanguage',
  get: ({ get }) => get(systemSettingState).language,
});
function useSystem() {
  const systemSetting = useRecoilValue(systemSettingState);

  const setSystemSetting = useRecoilCallback(
    ({ set }) =>
      (state: SystemSetting) =>
        set(systemSettingState, state),
    [],
  );

  const handleMessageRemindSettingChange = useCallback(
    async (messageRemind: SystemSetting['messageRemind']) => {
      let result = Notification.permission;
      if (messageRemind.popupRemind && result !== 'granted') {
        result = await window.Notification.requestPermission();
        result === 'denied' && message.warn('您已禁用浏览器通知，请在设置中手动开启');
      }
      setSystemSetting({
        ...systemSetting,
        messageRemind: {
          ...messageRemind,
          popupRemind: messageRemind.popupRemind ? result === 'granted' : false,
        },
      });
    },
    [setSystemSetting, systemSetting],
  );
  const handleLanguageSettingChange = useCallback(
    (language: General.Language) => {
      setSystemSetting({
        ...systemSetting,
        language,
      });
    },
    [setSystemSetting, systemSetting],
  );
  const handleCloseSettingChange = useCallback(
    (closeType: SystemSetting['closeType']) => {
      setSystemSetting({
        ...systemSetting,
        closeType,
      });
    },
    [setSystemSetting, systemSetting],
  );

  return {
    systemSetting,
    handleMessageRemindSettingChange,
    handleLanguageSettingChange,
    handleCloseSettingChange,
  };
}

export const useSystemLanguageValue = () => useRecoilValue(systemLanguageState);
export const useSystemSettingsValue = () => useRecoilValue(systemSettingState);

export default useSystem;
