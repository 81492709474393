import styled from '@emotion/styled/macro';

const Description = styled.div`
  width: 540px;
  > h3 {
    font-size: 16px;
  }
  > div {
    p {
      margin: 0;
      font-size: 14px;
      span {
        color: #336fff;
      }
    }
  }
`;

const Tips = styled.div`
  display: flex;
  margin: 15px 0;
  padding: 20px 20px 5px 20px;
  background: #f0f4ff;
  > div {
    flex: 1 0 0;
    > p {
      margin-bottom: 15px;
    }
  }
`;

export const cn = {
  nav: {
    account: '客服帐号管理',
    group: '客服组管理',
  },
  account: {
    fetchFailed: '获取帐号列表失败，请稍后再试',
    createAccount: '新增客服',
    showTotal: (total: number | string) => `共${total}项`,
    updateStatusSucceed: '客服状态保存成功',
    updateStatusFailed: '客服状态保存失败，请稍后再试',
    deleteSucceed: '删除客服成功',
    deleteFailed: '删除客服失败，请稍后再试',
    createAndCopy: '创建帐号并复制',
    createSucceed: '新增帐号成功',
    createFailed: '新增帐号失败，请稍后再试',
    copyAccountSucceed: '帐号信息已复制',
    copyAccountFailed: '复制帐号信息失败',
    updateNickSucceed: '修改昵称成功',
    updateNickFailed: '修改昵称失败，请稍后再试',
    updatePasswordSucceed: '修改密码成功',
    updatePasswordFailed: '修改密码失败，请稍后再试',
    validate: {
      accountEmpty: '请输入帐号',
      accountLimit: (limit: number) => `帐号不得超过${limit}个字`,
      accountReg: '帐号只可包含数字、英文字母和特殊符号',
      nickEmpty: '请输入昵称',
      nickLimit: (limit: number) => `昵称不得超过${limit}个字`,
      passwordEmpty: '请输入密码',
      passwordLimit: (minLimit: number, maxLimit: number) =>
        `密码长度不得超出${minLimit}～${maxLimit}个字的范围`,
      oldPasswordEmpty: '请输入旧密码',
      newPasswordEmpty: '请输入新密码',
      confirmPasswordEmpty: '请输入二次确认密码',
      confirmPassword: '二次确认与新密码不一致',
    },
    field: {
      account: '帐号',
      name: '昵称',
      password: '密码',
      oldPassword: '旧密码',
      newPassword: '新密码',
      confirmPassword: '二次确认',
      index: '序号',
      group: '客服组',
      permission: '权限',
    },
    placeholder: {
      account: '请输入帐号',
      name: '请输入昵称',
      password: '请输入密码',
      oldPassword: '请输入旧密码',
      newPassword: '请输入新密码',
      confirmPassword: '请再次输入新密码',
    },
    role: {
      ASSISTANT: '客服',
      SUPER_ADMIN: '管理员',
    },
    save: '保存',
    cancelCreateConfirm: '您确定要放弃新增帐号吗？',
    updateNick: '修改昵称',
    cancelUpdateNickConfirm: '您确定要放弃修改昵称吗？',
    updatePassword: '修改密码',
    delete: '删除',
    deleteConfirm: '您确定要删除该帐号吗？',
    enable: '启用',
    disable: '停用',
    disableConfirm: '您确定要停用该账号吗？',
    superAdminPlaceholder: '管理员不参与分流',
    operation: '操作',
  },
  group: {
    fetchFailed: '获取客服组列表失败，请稍后再试',
    fetchPriorityFailed: '获取优先接待范围失败，请稍后再试',
    deleteSucceed: '删除客服组成功',
    deleteFailed: '删除客服组失败，请稍后再试',
    saveSucceed: '客服组保存成功',
    saveFailed: '客服组保存失败，请稍后再试',
    empty: '当前没有客服组哦～',
    create: '新增客服组',
    priority: '优先接待范围',
    edit: '编辑',
    add: '添加',
    savePrioritySucceed: '优先接待范围保存成功',
    savePriorityFailed: '优先接待范围保存失败，请稍后再试',
    description: '使用说明',
    singleUndistributedStore: (name: string) =>
      `店铺：${name}未在任何客服组，买家消息无法分配至客服，请及时处理`,
    undistributedStoreList: (count: number | string) =>
      `当前有${count}个店铺未在任何客服组，买家消息无法分配至客服，请及时处理`,
    showTotal: (total: number) => `共${total}项`,
    tradeStatus: {
      NO_TRADE: '无订单',
      NO_PAY: '未付款',
      WAIT_SHIP: '待出货',
      SHIPPING: '运送中',
      RECENT_TRADED: '已完成7日内',
      REFUND: '退货退款',
    },
    buyerTag: {
      NEW: '新客',
      OLD: '老客',
    },
    receptionScopeType: {
      ALL_BUYER: '全部买家',
      TRADE_STATUS: '按照订单状态',
      BUYER_LABEL: '按照买家标签',
    },
    substituteGroupType: {
      ALL_GROUP: '全部客服组',
      CERTAIN_GROUP: '指定客服组',
      UNALTERNATIVE: '不可替补接待',
    },
    field: {
      tradeStatus: '订单状态',
      buyerTag: '买家标签',
      name: '客服组名称',
      userIds: '选择客服',
      storeIds: '适用店铺',
      receptionScope: '接待范围',
      substituteGroup: '繁忙时替补接待',
    },
    placeholder: {
      name: '请输入名称',
      userIds: '请选择客服',
      storeIds: '请选择店铺',
      substituteGroup: '请选择客服组',
    },
    validate: {
      nameEmpty: '请输入客服组名称',
      nameLimit: (limit: number | string) => `客服组名称不得超过${limit}个字`,
      userEmpty: '请选择客服',
      storeEmpty: '请选择适用店铺',
      tradeStatusEmpty: '请选择订单状态',
      buyerTagEmpty: '请选择买家标签',
      groupEmpty: '请选择指定客服组',
    },
    save: '保存',
    priorityCancelConfirm: '您确定要放弃修改优先接待范围吗？',
    undistributedAccount: '未分配',
    distributedToAllBuyerStore: '该店铺已有接待范围为全部买家客服组',
    undistributedStore: '无客服组',
    fetchAccountListFailed: '获取客服列表失败，请稍后再试',
    fetchStoreListFailed: '获取适用店铺列表失败，请稍后再试',
    basicInfo: '基础信息',
    groupConfig: '客服组配置',
    editGroup: '编辑客服组',
    cancelEditGroupConfirm: '您确定要放弃编辑客服组吗？',
    deleteConfirm: '您确定要删除该客服组吗？',
    delete: '删除分组',
    accountCount: '组内客服：',
    enableCount: (count: number | string) => `启用${count}位客服`,
    disableCount: (count: number | string) => `停用${count}位客服`,
    descriptionRenderer: () => (
      <Description>
        <h3>买家进行咨询后，我们会如何分配？</h3>
        <div>
          <p>
            1、买家有
            <span>最近联系客服</span>
            ，且
            <span>在线</span>
            ，则直接分配给已接待的客服。
          </p>
          <p>
            2、买家近3天内没有联系过客服，则根据买家
            <span>历史行为</span>和<span>最近更新状态的订单</span>
            ，将买家分配至对应
            <span>接待范围</span>
            的客服组。
          </p>
          <Tips>
            <span>Tips：</span>
            <div>
              <p>
                如买家满足多个
                <span>接待范围</span>
                的客服组时，每一个
                <span>接待范围</span>
                依次判断是否有对应的客服组进行分配，其中
                <span>优先接待范围</span>
                中的选项，会优先进行判断。
              </p>
              <p>
                <span>
                  如果买家满足的接待范围，没有任何一个客服组符合，则会分配至所属店铺的任意一个客服组
                </span>
              </p>
            </div>
          </Tips>
          <p>
            3、我们会在
            <span>客服组内分配客服</span>
            ，根据组内客服的繁忙度规则寻找一个客服进行分配。
          </p>
        </div>
      </Description>
    ),
  },
};

export const en = {
  nav: {
    account: 'Assistant Accounts',
    group: 'Assistant Groups',
  },
  account: {
    fetchFailed: 'Get account list failed, please try again later',
    createAccount: 'Add new assistant(s)',
    showTotal: (total: number | string) => `Total ${total} items`,
    updateStatusSucceed: 'Update account status succeed',
    updateStatusFailed: 'Update account status failed, please try again later',
    deleteSucceed: 'Delete account succeed',
    deleteFailed: 'Delete account failed, please try again later',
    createAndCopy: 'Create an account and copy',
    createSucceed: 'Create account succeed',
    createFailed: 'Create account failed, please try again later',
    copyAccountSucceed: 'Copy account info succeed',
    copyAccountFailed: 'Copy account info failed, please try again later',
    updateNickSucceed: 'Update account username succeed',
    updateNickFailed: 'Update account username failed, please try again later',
    updatePasswordSucceed: 'Update account password succeed',
    updatePasswordFailed: 'Update account password failed, please try again later',
    validate: {
      accountEmpty: 'Please input account',
      accountLimit: (limit: number) =>
        `${limit} characters in maximum, the upper limit has been reached`,
      accountReg: 'Account should only be composed by number letter and special character',
      nickEmpty: 'Please input username',
      nickLimit: (limit: number) =>
        `${limit} characters in maximum, the upper limit has been reached`,
      passwordEmpty: 'Please input password',
      passwordLimit: (minLimit: number, maxLimit: number) =>
        `${maxLimit} characters in maximum and ${minLimit} characters in minimum`,
      oldPasswordEmpty: 'Please input the old password',
      newPasswordEmpty: 'Please input new password',
      confirmPasswordEmpty: 'Please reconfirm new password',
      confirmPassword: 'The second confirmation is inconsistent with the new password',
    },
    field: {
      account: 'Account',
      name: 'Username',
      password: 'Password',
      oldPassword: 'Old password',
      newPassword: 'New password',
      confirmPassword: 'Reconfirm',
      index: 'Ordinal',
      group: 'Assistant groups',
      permission: 'Authority',
    },
    placeholder: {
      account: 'Please input account',
      name: 'Please input username',
      password: 'Please input password',
      oldPassword: 'Please input the old password',
      newPassword: 'Please input new password',
      confirmPassword: 'Please input the new password again',
    },
    role: {
      ASSISTANT: 'Assistant',
      SUPER_ADMIN: 'Administrator',
    },
    save: 'Save',
    cancelCreateConfirm: 'Are you sure to quit creating a new account?',
    updateNick: 'Change username',
    cancelUpdateNickConfirm: 'Are you sure to quit change username?',
    updatePassword: 'Change password',
    delete: 'Delete',
    deleteConfirm: 'Are you sure to delete this account?',
    enable: 'Enable',
    disable: 'Close',
    disableConfirm: 'Are you sure to close this account?',
    superAdminPlaceholder: 'Administrators are not in any groups.',
    operation: 'Operation',
  },
  group: {
    fetchFailed: 'Get assistant group list failed, please try again later',
    fetchPriorityFailed: 'Get prior service scope failed, please try again later',
    deleteSucceed: 'Delete assistant group succeed',
    deleteFailed: 'Delete assistant group failed, please try again later',
    saveSucceed: 'Save assistant group succeed',
    saveFailed: 'Save assistant group failed, please try again later',
    empty: 'No assistant groups are available now.',
    create: 'Add new assistant group(s)',
    priority: 'Prior Service Scope',
    edit: 'Edit',
    add: 'Add',
    savePrioritySucceed: 'Save prior service scope succeed',
    savePriorityFailed: 'Save prior service scope failed, please try again later',
    description: 'Instructions',
    singleUndistributedStore: (name: string) =>
      `Buyers' conversation cannot be accessed by any assiatants as store ${name} not in any of groups. Please deal with it in time.`,
    undistributedStoreList: (count: number | string) =>
      `Buyers' conversation cannot be accessed by any assiatants as there are ${count} stores not in any of groups. Please deal with it in time.`,
    showTotal: (total: number) => `Total ${total} items`,
    tradeStatus: {
      NO_TRADE: 'Placed no orders',
      NO_PAY: 'Unpaid',
      WAIT_SHIP: 'To Ship',
      SHIPPING: 'Shipping',
      RECENT_TRADED: 'Completed within 7 days',
      REFUND: 'Return/refund',
    },
    buyerTag: {
      NEW: 'New Customer',
      OLD: 'Regular Customer',
    },
    receptionScopeType: {
      ALL_BUYER: 'All buyers',
      TRADE_STATUS: 'By Order Status',
      BUYER_LABEL: "By Buyer's Label",
    },
    substituteGroupType: {
      ALL_GROUP: 'All assistant groups',
      CERTAIN_GROUP: 'Choose assistant group(s)',
      UNALTERNATIVE: 'No alternative assistants',
    },
    field: {
      tradeStatus: 'Order status',
      buyerTag: "Buyer' Label",
      name: 'Assistant group name',
      userIds: 'Choose assiatant(s)',
      storeIds: 'Choose store(s)',
      receptionScope: 'Service list',
      substituteGroup: 'Alternative assistants',
    },
    placeholder: {
      name: 'Enter name here',
      userIds: 'Please choose assistants',
      storeIds: 'Please choose stores',
      substituteGroup: 'Please select assistant group',
    },
    validate: {
      nameEmpty: 'Please input assistant group name',
      nameLimit: (limit: number | string) =>
        `${limit} characters in maximum, the upper limit has been reached`,
      userEmpty: 'Please select assistant',
      storeEmpty: 'Please select store',
      tradeStatusEmpty: 'Please select order status',
      buyerTagEmpty: "Please select buyer's label",
      groupEmpty: 'Please select assistant group',
    },
    save: 'Save',
    priorityCancelConfirm: 'Are you sure to quit edit prior service scope?',
    undistributedAccount: 'Not in any assistant groups',
    distributedToAllBuyerStore:
      'The service list of your store has been set as All assistant groups. ',
    undistributedStore: 'No assistant groups available',
    fetchAccountListFailed: 'Get assistant list failed, please try again later',
    fetchStoreListFailed: 'Get store list failed, please try again later',
    basicInfo: 'Basic Information',
    groupConfig: 'Assiatants group settings',
    editGroup: 'Edit assistant group',
    cancelEditGroupConfirm: 'Are you sure to quit creating a new assistant group?',
    deleteConfirm: 'Are you sure to delete the assistant group?',
    delete: 'Delete this group',
    accountCount: 'Assistants in the group: ',
    enableCount: (count: number | string) => `Open ${count} assistant(s)`,
    disableCount: (count: number | string) => `Close ${count} assistant(s)`,
    descriptionRenderer: () => (
      <Description>
        <h3>Customers will be allocated by following rules:</h3>
        <div>
          <p>
            1. Customers who inquried an assistant
            <span> recently </span>
            will be allocated directly to the assistant if he is
            <span> online</span>.
          </p>
          <p>
            2. Customers who did not inquired for 3 days will be allocated into an assistant group
            by his
            <span> historical behavior </span>
            or
            <span> latest order status</span>.
          </p>
          <Tips>
            <span>Tips：</span>
            <div>
              <p>
                If the customer can be alloted to more than one assiatant group with different
                <span> prior service list</span>, it shall be judged that each
                <span> prior service list </span>
                has a corresponding assistant group or not, and customers in
                <span> prior service list </span>
                will be judged first.
              </p>
              <p>
                <span>
                  Or if the customer is in different prior service lists and no assiatants serve
                  him, random assistant group shall serve him.
                </span>
              </p>
            </div>
          </Tips>
          <p>
            3. Assiatants will be
            <span> alloted </span>
            based on how busy they are.
          </p>
        </div>
      </Description>
    ),
  },
};
