export const cn = {
  upload: {
    preview: '图片预览',
    failed: '失败',
    attentions: '注意事项',
    limit: '最多上传3张图片，支持jpg，png，jpeg格式',
    upload: '上传图片',
    limitWarn: (size: string) => `上传图片超过最大限制，请上传不大于 ${size} 的图片`,
    previewFailed: '图片预览失败',
    uploadFailed: (name?: string) => `图片${name ? ` ${name} ` : ''}上传失败`,
    authorizeFailed: (name?: string) =>
      `授权过期,图片${name ? ` ${name} ` : ''}上传失败,请刷新页面后重试`,
  },
};

export const en = {
  upload: {
    preview: 'Preview image',
    failed: 'Failed',
    attentions: 'Attentions',
    limit: 'Upload 3 pictures in maximum, supporting jpg. png. or jpeg.',
    upload: 'Upload image',
    limitWarn: (size: string) => `Please upload pictures not larger than ${size}`,
    previewFailed: 'Preview image failed',
    uploadFailed: (name?: string) => `Upload image ${name ? ` ${name} ` : ''} failed`,
    authorizeFailed: (name?: string) =>
      `Image ${
        name ? ` ${name} ` : ''
      } upload failed due to authorization expired. Please refresh and retry.`,
  },
};
