import { useEffect, useRef } from 'react';

/**
 * 追踪副作用函数
 * @template TValue 追踪值
 */
export interface TrackingEffectFunction<TValue> {
  /**
   * @param current 当前的值
   * @param prev 上次的值
   */
  (current: TValue, prev: TValue): void | (() => void);
}

/**
 * 获取受追踪的引用对象
 * @template TValue 追踪值
 * @param value 追踪值
 * @param effect 追踪副作用函数
 */
function useTrackingRef<TValue>(value: TValue, effect?: TrackingEffectFunction<TValue>) {
  const trackingRef = useRef(value);
  const effectRef = useRef(effect);

  useEffect(() => {
    effectRef.current = effect;
  }, [effect]);

  useEffect(() => {
    const prev = trackingRef.current;
    trackingRef.current = value;

    if (effectRef.current) {
      return effectRef.current(value, prev);
    }

    return () => {};
  }, [value]);

  return trackingRef;
}

export default useTrackingRef;
