import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import AxiosClient, { Interceptors } from 'utils/AxiosClient';
import { isIM1 } from 'utils/is';
import logout from 'utils/logout';

export interface ImResponse<T = unknown> {
  code: number;
  msg: string;
  data: T;
}

export function resolve<T>(promise: Promise<AxiosResponse<ImResponse<T>>>) {
  return promise.then((response) => {
    return response.data.data;
  });
}

const interceptors: Interceptors = {
  response: [
    (response) => {
      const { headers } = response;

      const newToken = headers['new-jwt'];

      if (newToken) {
        const {
          location: { hash },
        } = window;

        const [hashPath, query] = hash.split('?');

        window.location.hash = `${hashPath}?${query ? `${query}&` : ''}token=${newToken}`;
      }

      return response;
    },
    (error) => {
      if (AxiosClient.isAxiosError(error)) {
        if (error.response?.status === 401) {
          notification.error({
            duration: 0,
            message: '未授权的访问',
            description: '授权信息已失效，请尝试重新授权',
          });

          logout();
        }

        if (error.response?.status === 403) {
          window.location.hash = '/403';
        }
      }

      throw error;
    },
  ],
};

const leyanbotHost = process.env.REACT_APP_LEYANBOT_HOST as string;
const authHeader = process.env.REACT_APP_AUTH_HEADER as string;

export const omsClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/overseaim-oms/v1`,
  },
  isIM1 ? undefined : interceptors,
);

export const storeHomeClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/overseaim-store-home/v1`,
  },
  isIM1 ? undefined : interceptors,
);
/**
 * Basically keep asame with storeHomeClient,
 * however, skip the default interceptors,
 * in most login case the 403/401 needn't a redirect.
 * and it doesn't need a pre-auth with valid token
 */
export const loginClient = new AxiosClient({
  baseURL: `${leyanbotHost}/overseaim-store-home/v1`,
});

/** 会话列表 */
export const conversationClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/oversea-door/oversea-conversation/v1`,
  },
  interceptors,
);

export const doorClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/oversea-door`,
  },
  interceptors,
);

export const wechatClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/wechat-service`,
  },
  interceptors,
);
export function authorize(token: string) {
  [
    ...(isIM1 ? [] : [omsClient]),
    storeHomeClient,
    conversationClient,
    doorClient,
    wechatClient,
  ].forEach((client) => {
    client.patchConfig({
      headers: {
        ...client.getConfig().headers,
        [authHeader]: `Bearer ${token}`,
      },
    });
  });
}
