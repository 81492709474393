import React, { useCallback, useEffect, useRef } from 'react';
import notification, { Message, NotifyOptions } from 'services/notification';
import useSystem from 'hooks/business/system/useSystem';
import FixedArray from 'utils/fixedArray';
import focusChatttClient from 'utils/focusChatttClient';
import Sound from './sound.wav';
import Icon from './chattt.png';

const NOTIFICATION_LIST_LENGTH = 10;
const Notification = () => {
  const ref = useRef<HTMLAudioElement>(null);
  const { systemSetting } = useSystem();
  /**
   * 使用一个定长数组来保证只会展示10条通知
   * 测试用例文档参考
   * @see https://www.teambition.com/project/6065909f1b6739cea346403e/testplan/60af3e2cf155db00442175e0/group/all/testcase/61233e59542c99003fe298c4
   */
  const fixedArray = useRef(new FixedArray<Notification>(NOTIFICATION_LIST_LENGTH));
  const {
    messageRemind: { soundRemind, popupRemind },
  } = systemSetting;
  const playSound = useCallback(
    (options?: NotifyOptions) => {
      if (options?.sound || soundRemind) {
        ref.current?.play();
      }
    },
    [soundRemind],
  );
  const notifyMessage = useCallback(
    (message: Message, options: NotifyOptions) => {
      if (!options.popup && !popupRemind) {
        return;
      }
      const notifyPopup = new window.Notification(message.title, {
        body: message.content,
        tag: message.id,
        icon: Icon,
      });
      // 将最新一条消息push到定长数组中维护
      fixedArray.current.push(notifyPopup, (item) => item.close());
      notifyPopup.onclick = () => {
        focusChatttClient();
        options.onClick(message.id, message.conversationId);
        // 点击某一条消息以后，需要从定长数组中移除
        fixedArray.current.remove(notifyPopup, (a, b) => a.tag === b.tag);
        notifyPopup.close();
      };
      notifyPopup.onclose = () => options.onClose(message.id, message.conversationId);
      setTimeout(() => {
        notifyPopup.close();
        // 展示时间超时需要从列表中删除
        fixedArray.current.remove(notifyPopup, (a, b) => a.tag === b.tag);
      }, options.duration);
    },
    [popupRemind],
  );
  const notify = useCallback(
    (message: Message, options: NotifyOptions) => {
      playSound(options);
      notifyMessage(message, options);
    },
    [notifyMessage, playSound],
  );
  useEffect(() => {
    notification.subscribe(notify);
    return () => {
      notification.unsubscribe(notify);
    };
  }, [notify]);
  return (
    <div>
      <audio ref={ref}>
        <source src={Sound} type="audio/wav" />
        <track kind="captions" />
      </audio>
    </div>
  );
};
Notification.displayName = 'Notification';
export default Notification;
