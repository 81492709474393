export const cn = {
  tw: '台湾虾皮',
  my: '马来西亚',
  id: '印度尼西亚',
  th: '泰国',
  ph: '菲律宾',
  sg: '新加坡',
  vn: '越南',
  br: '巴西',
};
export const en = {
  tw: 'Taiwai',
  my: 'Malaysia',
  id: 'Indonesia',
  th: 'Thailand',
  ph: 'Philippine',
  sg: 'Singapore',
  vn: 'Vietnam',
  br: 'Brazil',
};
