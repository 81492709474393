export const cn = {
  查看商品: '查看商品',
  订单金额: '订单金额',
  订单ID: '订单ID',
  下单时间: '下单时间',
  订单: '订单',
  时间: '时间',
  商品: '商品',
  商品卡片: '商品',
  系统消息: '系统消息',
  请输入: '请输入',
  翻译完成: '翻译完成',
  翻译中: '翻译中...',
  翻译失败: '翻译失败',
} as const;
type AllKeys = keyof typeof cn;
type TranslationSet = Record<AllKeys, string>;
export const en: TranslationSet = {
  查看商品: 'Check',
  订单金额: 'Total',
  订单: 'Order',
  商品卡片: 'RECOMMANDED',
  下单时间: 'Order Time',
  时间: 'Time',
  商品: 'Product',
  系统消息: 'Notifications',
  订单ID: 'Order ID',
  请输入: 'Enter here',
  翻译完成: 'Translated',
  翻译中: 'Translatiing...',
  翻译失败: 'Translate Failed',
};
const componentI18n = {
  cn,
  en,
};

export default componentI18n;
