import chatttSDK from 'services/chattt';

const focusChatttClient = () => {
  if (!chatttSDK?.version) {
    return false;
  }
  chatttSDK?.app.focus();
  return true;
};

export default focusChatttClient;
