export const cn: Record<General.Language, string> = {
  'zh-CN': '中文简体',
  'en-US': '英语',
  id: '印尼语',
  pt: '葡萄牙语',
  th: '泰语',
  vi: '越南语',
  'zh-TW': '中文繁体',
  tl: '菲律宾语',
  ms: '马来语',
};
export const en = {
  'zh-CN': 'Chinese',
  'en-US': 'English',
  id: 'Indonesian',
  pt: 'Portuguese',
  th: 'Thai',
  vi: 'Vietnamese',
  'zh-TW': 'Chinese',
  tl: 'Filipino',
  ms: 'Malay',
};
