import React from 'react';
import {
  BrowserRouter,
  HashRouter,
  MemoryRouter,
  BrowserRouterProps,
  HashRouterProps,
} from 'react-router-dom';

import { RouterConfig } from 'types';
import RouterQuery, { RouterQueryProps } from 'components/RouterQuery';

type MemoryRouterProps = React.ComponentProps<typeof MemoryRouter>;

/**
 * `history` 路由映射
 */
const ROUTER_MAP = {
  browser: BrowserRouter,
  hash: HashRouter,
  memory: MemoryRouter,
} as const;

/**
 * 路由属性
 */
export interface RouterProps
  extends Pick<RouterConfig, 'history'>,
    Omit<BrowserRouterProps & HashRouterProps & MemoryRouterProps, 'children'>,
    Pick<RouterQueryProps, 'children'> {
  queryParse?: RouterQueryProps['parse'];
  queryStringify?: RouterQueryProps['stringify'];
}

/**
 * 路由
 * @param props 路由属性
 */
function Router(props: RouterProps) {
  const {
    history = 'hash',
    window,
    initialEntries,
    initialIndex,
    queryParse,
    queryStringify,
    children,
  } = props;

  const { [history]: Router } = ROUTER_MAP;
  const routerProps = history === 'memory' ? { initialEntries, initialIndex } : { window };

  return (
    <Router {...routerProps}>
      <RouterQuery parse={queryParse} stringify={queryStringify}>
        {children}
      </RouterQuery>
    </Router>
  );
}

export default Router;
