import whiteCnImage from './assets/white.cn.png';
import whiteEnImage from './assets/white.en.png';
import themeCnImage from './assets/theme.cn.png';
import themeEnImage from './assets/theme.en.png';

export const cn = {
  whiteImage: whiteCnImage,
  themeImage: themeCnImage,
};

export const en = {
  whiteImage: whiteEnImage,
  themeImage: themeEnImage,
};
