export const cn = {
  已授权店铺: '已授权店铺',
  添加授权店铺: '添加授权店铺',
  'request.action.of.get.auth.url': '获取授权链接',
  'request.action.of.Authorization': '授权',
  'store.type': '授权店铺类型',
  'store.type.local': '本土店铺',
  'store.type.cross': '跨境店铺',
  'tips.of.get.auth.title': 'Tips: 需完成以下两项授权，才可以继续操作配置哦',
  'tips.of.get.auth.one': '授权店铺历史订单',
  'tips.of.get.auth.two': '授权店铺历史消息',
  'free.trial.days': '还剩余{{rest}}天试用结束',
  'col.store.name': '店铺名称',
  'col.store.name.remark': '店铺名称备注',
  'col.store.id': '店铺ID',
  'col.auth.time': '授权时间',
  'col.auth.status': '授权状态',
  'col.auth.status.active': '生效中',
  'col.auth.status.intial': '初始化中',
  'col.auth.status.unauthorized': '未授权',
  'col.auth.status.to.be.authorized': '待授权',
  'col.auth.status.to.be.failed': '授权失败',
  'col.actions': '操作',
  'col.actions.continue': '继续授权',
  authRememberPass: '记住授权店铺的登录账号和密码',
  authRememberPassTip: '用于快速同步买家咨询商品、订单的信息，帮助客服更加准确地接待买家；',
  authRememberPassTip2:
    '如不同意记住账号密码，将无法同步买家咨询的商品、订单信息，可能降低客服回复质量、进而影响买家咨询体验。',
  'shopee.auth.error.tips.of.codes.1005': '该店铺已被其他账号主体授权过，无法操作授权',
  'shopee.auth.result.modal.title': '店铺授权',
  'shopee.auth.result.modal.retry.text': '尝试重新授权',
  'shopee.auth.result.modal.continue.text': '继续授权',
  'shopee.auth.result.modal.back.text': '返回店铺授权列表',
  'shopee.auth.result.success': '授权成功',
  'shopee.auth.result.success.go.next': '当前已完成其中一项了哦， 还需去授权“历史订单/历史消息',
  'shopee.auth.result.failed': '授权失败',
};
export const en = {
  已授权店铺: 'Authorized Stores',
  添加授权店铺: 'Adding authorized stores',
  'request.action.of.get.auth.url': 'get auth aul',
  'request.action.of.Authorization': 'Authorization',
  'store.type': 'Store Type',
  'store.type.local': 'Local Store',
  'store.type.cross': 'Cross Store',
  'tips.of.get.auth.title': 'Tips: You must give the next two kinds of Authorizations to continue.',
  'tips.of.get.auth.one': 'Authorization of the order history',
  'tips.of.get.auth.two': 'Authorization of the messages history',
  'free.trial.days': 'Free trial will be ended in {{rest}} days',
  'col.store.name': 'Store Name',
  'col.store.name.remark': 'Store Remark',
  'col.store.id': 'Store ID',
  'col.auth.time': 'Authorization Time',
  'col.auth.status': 'Authorization Status',
  'col.auth.status.active': 'Active',
  'col.auth.status.intial': 'Initializing',
  'col.auth.status.unauthorized': 'Unauthorized',
  'col.auth.status.to.be.authorized': 'To be authorized',
  'col.auth.status.to.be.failed': 'Authorized failed',
  'col.actions': 'Actions',
  'col.actions.continue': 'Continue to authorize',
  authRememberPass: 'Remember the login account and password of the authorized store',
  authRememberPassTip:
    'This is used to help assistants serve the customers precisely by synchronizing the info of products and order.',
  authRememberPassTip2: `However it will not synchronize the info of products and order if you do not want to remember your account and password, which may influence the quality of reply and customers' feeling of inquiring.`,
  'shopee.auth.error.tips.of.codes.1005':
    'The Authorization was rejected because of this authorzation was occupied by another entity.',
  'shopee.auth.result.modal.retry.text': 'Retry',
  'shopee.auth.result.modal.continue.text': 'Continue',
  'shopee.auth.result.modal.back.text': 'Back',
  'shopee.auth.result.modal.title': 'Shopee Authorization',
  'shopee.auth.result.success': 'Authorize Success',
  'shopee.auth.result.success.go.next':
    "We did finish with the first Authorization. Let's go finish it.",
  'shopee.auth.result.failed': 'Authorize Failed',
};
export default { cn, en };
