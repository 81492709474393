import { en as assistant } from 'views/Assistant/i18n';
import { en as autoReply } from 'views/Setting/autoReply.i18n';
import { en as sider } from 'views/Main/components/AppSider/i18n';
import { en as header } from 'views/Main/components/AppHeader/i18n';
import { en as templateEditor } from 'components/TemplateEditor/i18n';
import { en as nim } from 'components/Nim/i18n';
import { en as logo } from 'components/Logo/i18n';
import { en as ConvList } from 'views/Conversation/ConvList/i18n';
import { en as ViewSystem } from 'views/Setting/System.i18n';
import { en as ViewShops } from 'views/Setting/Shops/Shops.i18n';
import { en as ViewsChat } from 'views/Conversation/Chat/i18n';
import { en as antd } from '../namespace/antd';
import { en as langs } from '../namespace/langs';
import { en as api } from '../namespace/api';
import { en as Entities } from '../namespace/entities';
import { en as region } from './defines/region';

const msgType = {
  STICKER: 'STICKER',
  IMAGE: 'IMAGE',
  ORDER: 'ORDER',
  ITEM: 'PRODUCT',
  ITEM_LIST: 'PRODUCT LIST',
  VOUCHER: 'VOUCHER',
  VIDEO: 'VIDEO',
  OFFER: 'OFFER',
  BUNDLE_MESSAGE: 'BUNDLE MESSAGE',
  FAQ_LIVEAGENT: 'FAQ LIVEAGENT',
  SYS_TRANSFER: 'TRANSFER',
  SYS_ASSIGN: 'ASSIGN',
  VOICE: 'VOICE',
  FILE: 'FILE',
} as const;

type MsgType = typeof msgType;

export default {
  translation: {
    region,
    sider,
    header,
    conversation: {
      header: {
        receptionBuyer: 'Today Reception Buyer',
        untreated: 'Untreated',
        content: 'Content',
        addGroup: 'Add Group',
      },
      convList: ConvList.convList,
      forward: ConvList.forward,
      channel: {
        s_program: 'miniprogram',
        wx_pay: 'wxpay',
        t_video: 'wechat video',
        t_public: 'offiaccount',
      },
    },
    setting: {
      nav: {
        shops: 'Shop Settings',
        system: 'System Settings',
        autoReply: 'Timeout Reply',
      },
    },
    templateEditor,
    loadMore: {
      loading: 'Loading...',
      noMore: 'There are no more',
      error: 'Get data failed, please click to retry',
    },
    msgType,
    当前暂无接待: "There's no clients right now",
    Translate: 'translate',
    Send: 'send',
    api,
    assistant,
    autoReply,
    notification: {
      content: (sender: string, msgType: MsgType[keyof MsgType]) =>
        `${sender} sent you a ${msgType}`,
      UNKNOWN_MESSAGE_TYPE: 'unknown message type',
      SYSTEM_MESSAGE: 'system message',
      NEW_MESSAGE_REMINDER: 'new message reminder',
    },
    nim,
    logo,
  },
  antd,
  langs,
  ViewsChat,
  ViewSystem,
  ViewShops,
  Entities,
} as const;
