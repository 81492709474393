export const cn = {
  status: {
    online: '在线',
    hangup: '挂起',
    offline: '离线',
    operation: {
      offline: '当前会话未成功连接，请稍后再试',
      online: {
        succeed: '设置客服在线成功',
        failed: '设置客服在线失败，请稍后再试',
        confirm: {
          title: '账号在线',
          content: '设置为“在线”后，将会有新的客户接入，你确定要在线吗',
        },
      },
      hangup: {
        succeed: '设置客服挂起成功',
        failed: '设置客服挂起失败，请稍后再试',
        confirm: {
          title: '账号挂起',
          content: '设置为“挂起”后，将不会有新的客户接入，您确定挂起吗',
        },
      },
    },
    fetchStatusFailed: '获取客服在线状态失败',
  },
  nav: {
    conversation: '接待',
    marketing: '营销',
    qa: '智能客服',
    setting: '设置',
    assistant: '客服管理',
  },
  other: {
    more: '更多',
    language: '语言切换',
    logout: '退出登录',
    changePassword: '修改密码',
    loggingout: '正在退出工作台',
  },
};

export const en = {
  status: {
    online: 'Online',
    hangup: 'HangUp',
    offline: 'Offline',
    operation: {
      offline: 'Conversation unconnected, please try again later',
      online: {
        succeed: 'Succeed to set it online',
        failed: 'Failed to set it online, please try again later',
        confirm: {
          title: 'Account Online',
          content:
            'New customers will be in service list if you set it as "Online". Are you sure to set?',
        },
      },
      hangup: {
        succeed: 'Succeed to set it hang up',
        failed: 'Failed to set it hang up, please try again later',
        confirm: {
          title: 'Hang up the account',
          content:
            'New customers will not be in the service list if you set it as "Hang Up". Are you sure to set?',
        },
      },
    },
    fetchStatusFailed: 'Get online info failed',
  },
  nav: {
    conversation: 'Serve',
    marketing: 'Marketing',
    qa: 'RoboChat',
    setting: 'Settings',
    assistant: 'Assistant',
  },
  other: {
    more: 'More',
    language: 'Language',
    logout: 'Log out',
    changePassword: 'Change password',
    loggingout: 'Logging Out',
  },
};
