import { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import cnLocale from 'antd/es/locale/zh_CN';
import enLocale from 'antd/es/locale/en_US';
import { ThemeProvider } from '@emotion/react/macro';
import { Result, Button, ConfigProvider } from 'antd';

import routerConfig from 'services/config/router';
import ErrorBoundary from 'components/ErrorBoundary';
import AppLoading from 'components/AppLoading';
import Router from 'components/Router';
import RouterRender from 'components/RouterRender';
import Authorization from 'components/Authorization';
import authorization from 'services/config/authorization';
import RouterMeta from 'components/RouterMeta';
import Slot from 'components/Slot';
import Notification from 'components/Notification';
import { useSystemLanguageValue } from 'hooks/business/system/useSystem';

const {
  ConfigContext: { Consumer: ConfigConsumer },
} = ConfigProvider;

const languageLocaleMap = {
  'zh-CN': cnLocale,
  'en-US': enLocale,
  'zh-TW': cnLocale,
  id: cnLocale,
  th: cnLocale,
  vi: cnLocale,
  pt: cnLocale,
  tl: enLocale,
  ms: enLocale,
};

function Entry() {
  const language = useSystemLanguageValue();
  const locale = languageLocaleMap[language];
  return (
    <ConfigProvider locale={locale}>
      <ConfigConsumer>
        {({ getPrefixCls }) => {
          return (
            <ThemeProvider theme={{ getAntdPrefixedClassName: getPrefixCls }}>
              <Slot>
                <Router {...routerConfig}>
                  <Authorization
                    storage={authorization.storage}
                    cacheKey={authorization.cacheKey}
                    queryKey={authorization.queryKey}
                    onAuthorize={authorization.onAuthorize}
                    onUnauthorize={authorization.onUnauthorize}
                  >
                    <RouterMeta basename={routerConfig.basename} routes={routerConfig.routes}>
                      {({ basename, permissionedRoutes }) => {
                        return <RouterRender basename={basename} routes={permissionedRoutes} />;
                      }}
                    </RouterMeta>
                    <Notification />
                  </Authorization>
                </Router>
              </Slot>
            </ThemeProvider>
          );
        }}
      </ConfigConsumer>
    </ConfigProvider>
  );
}

/**
 * 应用入口
 */
function App() {
  return (
    <ErrorBoundary
      fallback={(error) => {
        return (
          <Result
            status="500"
            title="应用加载失败"
            subTitle="请稍后尝试手动重新加载"
            extra={
              <Button type="primary" onClick={() => window.location.reload()}>
                立即重新加载
              </Button>
            }
          />
        );
      }}
    >
      <Suspense fallback={<AppLoading />}>
        <RecoilRoot>
          <Entry />
        </RecoilRoot>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
