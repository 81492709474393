// import authorizationCache from 'services/config/authorizationCache';

function logout() {
  // const { storage = sessionStorage, cacheKey = `@${process.env.REACT_APP_NAME}-token` } =
  //   authorizationCache;
  // storage.removeItem(cacheKey);
  // const {
  //   location: { origin, search },
  // } = window;
  // window.location.replace(`${origin}${search ? '/' : '/?chat=++'}#/login`);
}

export default logout;
