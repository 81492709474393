import { createContext } from 'react';

/**
 * 创建命名的上下文
 * @template TValue 上下文值
 * @param name 名称
 * @param defaultValue 上下文默认值
 */
function createNamedContext<TValue>(name: string, defaultValue: TValue) {
  const context = createContext(defaultValue);

  if (process.env.NODE_ENV !== 'production') {
    context.displayName = name;
  }

  return context;
}

export default createNamedContext;
