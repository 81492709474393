import {
  cnCommonApiGetErr,
  enCommonApiGetErr,
  cnCommonApiPostErr,
  enCommonApiPostErr,
} from './util';

export const cn = {
  queryConversationListErr: cnCommonApiGetErr('会话列表'),
  getTalkInfo: cnCommonApiPostErr('单个会话'),
  queryReceptionBuyerStatErr: cnCommonApiGetErr('统计数据'),
  getStoreListErr: cnCommonApiGetErr('店铺列表'),
  assistantGroupOnlineStatusErr: cnCommonApiGetErr('客服组列表'),
  assistantListOnlineStatusStatusErr: cnCommonApiGetErr('客服列表'),
  transferConversationErr: cnCommonApiPostErr('转发会话'),
  updateConversationErr: cnCommonApiPostErr('会话更新'),
  'common.action.request': '请求',
  'common.action.save': '保存',
  'common.error.messages': '{{action}}失败，请稍后再试。',
  'common.success.messages': '{{action}}成功！',
};
export const en = {
  queryConversationListErr: cnCommonApiGetErr('conversation list'),
  getTalkInfo: enCommonApiPostErr('single conversation'),
  queryReceptionBuyerStatErr: enCommonApiGetErr('buyer reception data'),
  getStoreListErr: enCommonApiGetErr('store list'),
  assistantGroupOnlineStatusErr: enCommonApiGetErr('assistant group list'),
  assistantListOnlineStatusStatusErr: enCommonApiGetErr('assistant list'),
  transferConversationErr: enCommonApiPostErr('Transfer conversation'),
  updateConversationErr: enCommonApiPostErr('Conversation update'),
  'common.action.request': 'Query',
  'common.action.save': 'Save',
  'common.error.messages': 'Failed to {{action}}, please try again later.',
  'common.success.messages': '{{action}} success!',
};
