import 'antd/dist/antd.css';
import 'react-app-polyfill/stable';
import 'moment/locale/zh-cn';
import moment from 'moment';
import numeral from 'numeral';
import { injectGlobal } from '@emotion/css';
import '@emotion/styled';
import ysbth from 'assets/fonts/ysbth.ttf';
import 'services/i18n';
import 'global.css';

injectGlobal`
  @font-face {
    font-family: you she biao ti hei;
    src: url(${ysbth});
  }
`;

numeral.defaultFormat('0,0');
numeral.nullFormat('-');

moment.locale('zh-cn');
