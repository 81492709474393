import Cache from 'utils/Cache';
import NoticeCache from 'utils/NoticeCache';

const PREFIX = process.env.REACT_APP_NAME || '';

export const localStorageCache = new Cache(localStorage);
export const sessionStorageCache = new Cache(sessionStorage);

export const noticeCache = new NoticeCache(localStorage, `${PREFIX}/notice`);

export const chatPreferenceStorageCache = new Cache(localStorage, `${PREFIX}/chat-pref`);
export const tempPermissionSessionCache = new Cache(sessionStorage, `${PREFIX}/temp-permission`);
