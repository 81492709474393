export const cn = {
  operation: {
    edit: '编辑',
    delete: '删除',
    deleteConfirm: '您确定删除该自动回复吗？',
    deleteSucceed: '删除成功',
    deleteFailed: '删除失败，请稍后再试',
    switch: {
      checked: '启用中',
      unchecked: '未启用',
      checkedSucceed: '启用成功',
      checkedFailed: '启用失败，请稍后再试',
      unCheckedSucceed: '关闭成功',
      unCheckedFailed: '关闭失败，请稍后再试',
    },
    fetchFailed: '获取超时自动回复列表失败，请稍后再试',
    fetchStoreListFailed: '获取店铺列表失败，请稍后再试',
    saveSucceed: '保存成功',
    saveFailed: '保存失败，请稍后再试',
    uploadFailedConfirm: '部分图片未上传成功，是否继续保存超时自动回复？',
  },
  field: {
    region: '站点',
    storeList: '适用店铺',
    delay: '延迟时间',
    frequency: '频率限制',
    content: '回复内容',
    operation: '操作',
  },
  form: {
    create: '新建自动回复',
    update: '编辑自动回复',
    save: '保存',
    label: {
      replySettings: '回复配置',
      translation: '翻译',
    },
    placeholder: {
      region: '请选择站点',
      storeList: '请选择店铺',
    },
    tips: {
      regionChange: '更改站点后，您配置的适用店铺和回复图片将会被清空，确认更改？',
      delay: '若客服未在设定的延时时间内回复，则自动回复指定信息。',
      frequency: '同一买家在限定时间内只回复一次。',
      unAuthorized: '尚未授权',
      qaEnabled: '该店已开通问答功能，请前往问答配置',
      storeUsed: '您已配置过该店铺',
    },
    validate: {
      region: '请选择站点',
      storeList: '请选择适用店铺',
      delay: '请输入延迟时间',
      frequency: '请输入频率限制',
      contentEmpty: '请输入回复话术',
      contentLimit: (contentLimit: number) => `回复话术不得超过${contentLimit}个字`,
    },
    translate: '翻译成站点语言',
  },
  tips: '启用后，若客服未在设定的延迟时间内未回复，则自动回复指定信息',
  image: '图片',
  minute: '分钟',
  hour: '小时',
  times: '次',
  showTotal: (total: number | string) => `共${total}项`,
};

export const en = {
  operation: {
    edit: 'Edit',
    delete: 'Delete',
    deleteConfirm: 'Are you sure to delete this Auto-Reply?',
    deleteSucceed: 'Delete succeed',
    deleteFailed: 'Failed to delete, please try again later',
    switch: {
      checked: 'Enabled',
      unchecked: 'Disabled',
      checkedSucceed: 'Enable succeed',
      checkedFailed: 'Failed to enable, please try again later',
      unCheckedSucceed: 'Disable succeed',
      unCheckedFailed: 'Failed to disable, please try again later',
    },
    fetchFailed: 'Get Auto-Reply list failed, please try again later',
    fetchStoreListFailed: 'Get store list failed, please try again later',
    saveSucceed: 'Save succeed',
    saveFailed: 'Failed to save, please try again later',
    uploadFailedConfirm:
      'There are images upload not succeed. Are you sure to save this Auto-Reply?',
  },
  field: {
    region: 'Station',
    storeList: 'Store(s)',
    delay: 'Delay bound',
    frequency: 'Frequency limitation',
    content: 'Answer',
    operation: 'Operation',
  },
  form: {
    create: 'Create Auto-Reply',
    update: 'Edit Auto-Reply',
    save: 'Save',
    label: {
      replySettings: 'Reply Settings',
      translation: 'Translation',
    },
    placeholder: {
      region: 'Please select a station',
      storeList: 'Please select store list',
    },
    tips: {
      regionChange:
        'All the stores and pictures you set will be cleared if you change the station. Are you sure to change it?',
      delay:
        'Specified information will be sent out automatically if there is no assistants to answer the customer after the set time.',
      frequency: 'The same buyer will only respond once within a limited time.',
      unAuthorized: 'Not authorized',
      qaEnabled: 'You have opened the Auto-Reply function, please go to the Auto-Reply Settings.',
      storeUsed: 'You have already set this store.',
    },
    validate: {
      region: 'Please select station',
      storeList: 'Please select store list',
      delay: 'Please input delay bound',
      frequency: 'Please input frequency limitation',
      contentEmpty: 'Please input answer',
      contentLimit: (contentLimit: number) => `Answer should be in ${contentLimit} words or less`,
    },
    translate: 'Translate',
  },
  tips: 'When you open it, specified information will be sent out automatically if there is no assistants to answer the customer after the set time.',
  image: 'image',
  minute: 'minute',
  hour: 'hour',
  times: 'times',
  showTotal: (total: number | string) => `Total ${total} items`,
};
