export const cn = {
  '买家语言为：': '买家语言为：',
  翻译失败: '翻译失败',
  自动翻译: '自动翻译',
  以上为历史消息: '以上为历史消息',
  星标客户: '星标客户',
  'message.editor.tips.forwarded': '已转发',
  'ai.reply.flag': '【机器人回复】',
  'message.last.read.tips': '上次读到这里',
  'message.ends.tips': '没有更多了',
  'message.system.supervise.forward': '由超级管理员从{{from}}转发给{{to}}',
  'message.system.forward': '由{{from}}转发给{{to}}',
  'message.system.forward.remark': '【备注: {{content}}】',
  'message.lazy.card.error.tips': '【{{type}}信息加载失败】',
  'message.lazy.card.sticker.not.found': '表情图片加载失败',
  'message.system.assign': '由系统转发给{{to}}',
  'chat.garanteed.assign.tips':
    '该买家不在您所在客服组的服务范围，但由于暂时无人接待，为保证买家问题能被及时响应，请帮助接待。如频繁出现，请及时联系管理员检查客服组配置是否合理。',
};
export const en = {
  自动翻译: 'Auto Translate',
  翻译失败: 'Translate Faild',
  '买家语言为：': "Buyer's language:",
  星标客户: 'Star',
  以上为历史消息: 'Notification History',
  'message.last.read.tips': 'Last Readed',
  'message.ends.tips': 'No more messages',
  'message.editor.tips.forwarded': 'Forwarded',
  'ai.reply.flag': '[AI Reply]',
  'message.system.supervise.forward': 'Forwarded to {{to}} from {{from}} by Superviser',
  'message.system.forward': 'Forwarded to {{to}} from {{from}}',
  'message.system.forward.remark': '[Forward Notes: {{content}}]',
  'message.lazy.card.error.tips': '[Failed to load the {{type}} Infomation]',
  'message.system.assign': 'Forwarded to {{to}} by the system',
  'message.lazy.card.sticker.not.found': 'Sticker Not Found',
  'chat.garanteed.assign.tips':
    'This buyer has not been served. Help to serve him to make sure that his inquires are answerd though he was not in your service list. If it happens frequently, please contact the administrator without delay to check whether the assistant group allocation is reasonable.',
};
