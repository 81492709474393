import { cn as assistant } from 'views/Assistant/i18n';
import { cn as autoReply } from 'views/Setting/autoReply.i18n';
import { cn as sider } from 'views/Main/components/AppSider/i18n';
import { cn as header } from 'views/Main/components/AppHeader/i18n';
import { cn as templateEditor } from 'components/TemplateEditor/i18n';
import { cn as nim } from 'components/Nim/i18n';
import { cn as logo } from 'components/Logo/i18n';
import { cn as ConvList } from 'views/Conversation/ConvList/i18n';
import { cn as ViewsChat } from 'views/Conversation/Chat/i18n';
import { cn as ViewSystem } from 'views/Setting/System.i18n';
import { cn as ViewShops } from 'views/Setting/Shops/Shops.i18n';
import { cn as langs } from '../namespace/langs';
import { cn as antd } from '../namespace/antd';
import { cn as Entities } from '../namespace/entities';
import { cn as api } from '../namespace/api';
import { cn as region } from './defines/region';

const msgType = {
  STICKER: '表情',
  IMAGE: '图片',
  ORDER: '订单',
  ITEM: '商品',
  ITEM_LIST: '商品列表',
  VOUCHER: '优惠券',
  VIDEO: '视频',
  OFFER: '议价',
  BUNDLE_MESSAGE: 'FAQ对话',
  FAQ_LIVEAGENT: 'Faq通知',
  SYS_TRANSFER: '转发',
  SYS_ASSIGN: '系统消息',
  VOICE: '语音',
  FILE: '文件',
} as const;
type MsgType = typeof msgType;

export default {
  translation: {
    sider,
    header,
    conversation: {
      header: {
        receptionBuyer: '今日接待',
        untreated: '待处理',
        content: '添加联系人数',
        addGroup: '邀请入群数',
      },
      convList: ConvList.convList,
      forward: ConvList.forward,
      channel: {
        s_program: '小程序',
        wx_pay: '微信支付',
        t_video: '视频号',
        t_public: '公众号',
      },
    },
    setting: {
      nav: {
        shops: '店铺管理',
        system: '系统设置',
        autoReply: '超时自动回复',
      },
    },
    region,
    templateEditor,
    loadMore: {
      loading: '玩命加载中...',
      noMore: '我是有底线的',
      error: '加载失败，点击重试',
    },
    msgType,
    当前暂无接待: '当前暂无接待',
    Translate: '翻译',
    Send: '发送',
    api,
    assistant,
    autoReply,
    notification: {
      content: (sender: string, msgType: MsgType[keyof MsgType]) =>
        `${sender}向你发送了${
          msgType === '图片' || msgType === '优惠券' ? '一张' : '一个'
        }${msgType}`,
      UNKNOWN_MESSAGE_TYPE: '未知消息类型',
      SYSTEM_MESSAGE: '系统消息',
      NEW_MESSAGE_REMINDER: '新消息提醒',
    },
    nim,
    logo,
  },
  langs,
  antd,
  ViewsChat,
  Entities,
  ViewSystem,
  ViewShops,
} as const;
