import React from 'react';
import { keyframes } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

const foldCubeAngle = keyframes`
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
`;

const Stage = styled.div`
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  pointer-events: none;
`;

const FoldingCube = styled.div`
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
`;

const Cube1 = styled.div`
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    animation: ${foldCubeAngle} 2.4s infinite linear both;
    transform-origin: 100% 100%;
  }
`;

const Cube2 = styled(Cube1)`
  transform: scale(1.1) rotateZ(90deg);

  &::before {
    animation-delay: 0.3s;
  }
`;

const Cube3 = styled(Cube1)`
  transform: scale(1.1) rotateZ(270deg);

  &::before {
    animation-delay: 0.9s;
  }
`;

const Cube4 = styled(Cube1)`
  transform: scale(1.1) rotateZ(180deg);

  &::before {
    animation-delay: 0.6s;
  }
`;

/**
 * 应用加载器属性
 */
export interface AppLoadingProps extends React.HTMLAttributes<HTMLElement> {}

/**
 * 应用加载器
 * @param props 应用加载器属性
 */
function AppLoading(props: AppLoadingProps) {
  return (
    <Stage {...props}>
      <FoldingCube>
        <Cube1 />
        <Cube2 />
        <Cube3 />
        <Cube4 />
      </FoldingCube>
    </Stage>
  );
}

export default AppLoading;
