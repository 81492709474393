import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, cn } from './resources';

export const ns = Object.keys(cn);

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    // 语言码 统一使用 i18n 标准语言定义
    resources: {
      'en-US': en,
      'zh-CN': cn,
    },
    lng: 'zh-CN',
    fallbackLng: 'zh-CN',
    ns,
    defaultNS: 'translation',
  });
};
initI18n();
export default i18n;
