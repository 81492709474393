import Cache from 'utils/Cache';

class NoticeCache extends Cache {
  getNotice(key: string) {
    const entries = super.get<Record<string, boolean>>();

    if (entries) {
      const { [key]: value = null } = entries;

      return value;
    }

    return null;
  }

  setNotice(key: string, visible: boolean | null) {
    const entries = super.get<Record<string, boolean>>();

    if (entries) {
      super.set({
        ...entries,
        [key]: visible,
      });
    } else {
      super.set({
        [key]: visible,
      });
    }

    return this;
  }

  removeNotice(key: string) {
    const entries = super.get<Record<string, boolean>>();

    if (entries && entries[key] !== undefined) {
      const { [key]: value, ...nextEntries } = entries;

      super.set(nextEntries);
    }

    return this;
  }

  getNoticeValue(key: string) {
    const entries = super.get<Record<string, number>>();

    if (entries) {
      const { [key]: value = 0 } = entries;

      return value;
    }

    return 0;
  }
}

export default NoticeCache;
